import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import EditIcon from '@mui/icons-material/Edit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CloseIcon from '@mui/icons-material/Close'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import _ from 'lodash'
import dayjs from 'dayjs'

import { useAppSelector, useAppDispatch } from '../hooks'
import { RootState } from '../stores'
import { displayName, shopCustomerName } from '../customerUtil'
import { updateCustomer } from '../stores/customers'
import { fetchCustomerShopLogs } from '../stores/customerShopLogs'
import {
  setSelectedCustomer,
  fetchCustomers,
  resetCustomersData,
} from '../stores/customers'
import { PC_MIN_WIDTH } from '../const'
import { setDisplayProfile } from '../stores/layout'
import { Company, Customer } from '../types'
import CustomerProfileImage from './CustomerProfileImage'
import CustomerConnectedStatusIcon from './CustomerConnectedStatusIcon'
import LineConnectModal from './LineConnectModal'
import RestockSubscriptionList from './RestockSubscriptionList'
import { fetchCustomerAddedLineRoute } from '../stores/addedLineRoute'

const shopItemDefaultImg =
  'https://omiseconnect-assets.s3.ap-northeast-1.amazonaws.com/images/shop/shop_item_default.png'

interface Props {
  company: Company
  customer: Customer
}

const CustomerProfile = ({ company, customer }: Props) => {
  const dispatch = useAppDispatch()
  const [nameInputValue, setNameInputValue] = useState('')
  const [noteInputValue, setNoteInputValue] = useState('')
  const [nameEditing, setNameEditing] = useState(false)
  const [noteEditing, setNoteEditing] = useState(false)
  const [customerShopLogLoading, setCustomerShopLogLoading] = useState(false)
  const [addedLineRouteLoading, setAddedLineRouteLoading] = useState(false)
  const { orders, accessLogs } = useAppSelector(
    (state: RootState) => state.customerShopLogs
  )
  const { isAdmin } = useAppSelector((state: RootState) => state.company)
  const { title: addedLineRouteTitle } = useAppSelector(
    (state: RootState) => state.addedLineRoute
  )

  useEffect(() => {
    if (customer) {
      setNameInputValue(displayName(customer))
      setNoteInputValue(customer.managementNote || '')
      setCustomerShopLogLoading(true)
      setAddedLineRouteLoading(true)
      dispatch(fetchCustomerShopLogs(company, customer)).then(() => {
        setCustomerShopLogLoading(false)
      })
      dispatch(fetchCustomerAddedLineRoute(company, customer)).then(() => {
        setAddedLineRouteLoading(false)
      })
    }
  }, [customer?.id])

  const handleClickSpamButton = () => {
    if (
      customer.spamAt ||
      window.confirm(
        'トークルームが「スパム」に移動され、新着メッセージの通知が届かなくなります。スパムに設定しますか？'
      )
    ) {
      const params = {
        spam_at: customer.spamAt ? null : new Date().toString(),
      }
      dispatch(updateCustomer(company, customer, params))
        .then(() => {
          dispatch(setSelectedCustomer(null))
          history.pushState(
            '',
            '',
            `${isAdmin ? '/admin' : ''}/companies/${company.slug}/talk_rooms`
          )
          dispatch(resetCustomersData())
          dispatch(fetchCustomers(company))
        })
        .catch(() => {
          window.alert(
            '変更内容の保存ができませんでした。ページを再読み込みしていただくか、時間をあけてから再度お試しください。'
          )
        })
    }
  }

  const handleSubmitName = () => {
    const params = { management_name: nameInputValue }
    dispatch(updateCustomer(company, customer, params))
      .then(() => {
        setNameEditing(false)
      })
      .catch(() => {
        window.alert(
          '変更内容の保存ができませんでした。ページを再読み込みしていただくか、時間をあけてから再度お試しください。'
        )
      })
  }

  const handleSubmitNote = () => {
    const params = { management_note: noteInputValue }
    dispatch(updateCustomer(company, customer, params))
      .then(() => {
        setNoteEditing(false)
      })
      .catch(() => {
        window.alert(
          '変更内容の保存ができませんでした。ページを再読み込みしていただくか、時間をあけてから再度お試しください。'
        )
      })
  }

  const dateGroupedAccessLogs = _.groupBy(accessLogs, accessLog => {
    return dayjs(accessLog.timestamp).format('YYYY/MM/DD')
  })

  if (!customer) return <div />

  return (
    <>
      <Profile>
        <div id={`form-root-${customer.id}`}>
          {customer.customerLineAccount && (
            <>
              <SettingsButton>
                <MoreVertIcon />
              </SettingsButton>
              <div uk-dropdown="mode: click" className="uk-padding-small">
                <ul className="uk-nav uk-dropdown-nav uk-nav-divider">
                  <li>
                    <LineConnectModal
                      rootId={`form-root-${customer.id}`}
                      company={company}
                      customer={customer}
                    />
                  </li>
                  <li>
                    <a onClick={handleClickSpamButton}>
                      {customer.spamAt ? 'スパムを解除' : 'スパムに設定'}
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        {customer.customerLineAccount && (
          <CloseButton onClick={() => dispatch(setDisplayProfile(false))}>
            <CloseIcon />
          </CloseButton>
        )}
        <CustomerProfileImage
          customer={customer}
          style={{
            objectFit: 'cover',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            border: '1px solid rgba(0, 0, 0, 0.05)',
            marginRight: '10px',
          }}
          requiredLineBadge={true}
        />
        {nameEditing ? (
          <DisplayNameForm>
            <input
              type="text"
              value={nameInputValue}
              onChange={e => setNameInputValue(e.target.value)}
              placeholder={displayName(customer)}
              className="uk-input"
            />
            <div className="uk-flex uk-flex-center uk-margin-small-top">
              <div
                onClick={() => setNameEditing(false)}
                className="uk-button uk-button-small uk-button-default uk-margin-small-right"
              >
                キャンセル
              </div>
              <div
                onClick={handleSubmitName}
                className="uk-button uk-button-small uk-button-primary"
              >
                保存
              </div>
            </div>
          </DisplayNameForm>
        ) : (
          <DisplayNameText>
            <h2>{displayName(customer)}</h2>
            <EditIcon
              onClick={() => setNameEditing(true)}
              style={{ fontSize: 20, cursor: 'pointer' }}
            />
          </DisplayNameText>
        )}
      </Profile>
      <DetailContent>
        <Heading style={{ marginTop: 10 }}>プロフィール</Heading>
        <div>
          <ProfileRow>
            <CustomerConnectedStatusIcon
              shopConnected={Boolean(customer.shopCustomer)}
              browserConnected={customer.firstOcidConnectedAt !== null}
              shopAccountType={company.shopAccountType}
              style={{
                fontSize: '13px',
                height: '28px',
                lineHeight: '28px',
                flexShrink: '0',
              }}
              type={'line'}
            />
            {customer.customerLineAccount ? (
              <p className="uk-text-break">
                {customer.customerLineAccount.displayName}
              </p>
            ) : (
              <p className="uk-text-small uk-text-muted">紐付いていません</p>
            )}
          </ProfileRow>
          <ProfileRow>
            <CustomerConnectedStatusIcon
              shopConnected={Boolean(customer.shopCustomer)}
              browserConnected={customer.firstOcidConnectedAt !== null}
              shopAccountType={company.shopAccountType}
              style={{
                fontSize: '13px',
                height: '28px',
                lineHeight: '28px',
                flexShrink: '0',
              }}
              type={'shop'}
            />
            {customer.shopCustomer ? (
              <p className="uk-text-break">
                {shopCustomerName(customer) ? (
                  <span>
                    {shopCustomerName(customer)}
                    <br />
                  </span>
                ) : null}
                <span className="uk-text-small uk-text-muted">
                  {customer.shopCustomer.email}
                </span>
              </p>
            ) : customer.firstOcidConnectedAt ? (
              <div
                className="uk-text-small uk-text-muted"
                style={{ margin: '12px', fontSize: '13px' }}
              >
                ブラウザの閲覧履歴が参照可能
                <br />
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://docs.omiseconnect.jp/docs/line_friends_analysis#block-1f45f9a6793b41ce8595819b12d07165"
                >
                  <span className="text-content">
                    ブラウザ紐付きとは？
                    <span
                      className="material-icons"
                      style={{ fontSize: 18, verticalAlign: 'middle' }}
                    >
                      open_in_new
                    </span>
                  </span>
                </a>
              </div>
            ) : (
              <p className="uk-text-small uk-text-muted">紐付いていません</p>
            )}
          </ProfileRow>
        </div>
        <hr />
        {customer.customerLineAccount && (
          <>
            {addedLineRouteLoading ? (
              <>
                <div className="uk-text-small uk-text-muted">
                  データ取得中...
                </div>
                <hr />
              </>
            ) : (
              addedLineRouteTitle && (
                <>
                  <Heading>LINE友だち追加経路</Heading>
                  <div className="uk-text-small">{addedLineRouteTitle}</div>
                  <hr />
                </>
              )
            )}
            <Heading>メモ</Heading>
            {noteEditing ? (
              <div>
                <textarea
                  value={noteInputValue}
                  onChange={e => setNoteInputValue(e.target.value)}
                  className="uk-textarea"
                  rows={4}
                />
                <div className="uk-flex uk-flex-center uk-margin-small-top">
                  <div
                    onClick={() => setNoteEditing(false)}
                    className="uk-button uk-button-small uk-button-default uk-margin-small-right"
                  >
                    キャンセル
                  </div>
                  <div
                    onClick={handleSubmitNote}
                    className="uk-button uk-button-small uk-button-primary"
                  >
                    保存
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`uk-text-small ${
                  customer.managementNote ? '' : 'uk-text-muted'
                }`}
              >
                {(customer.managementNote || 'メモはありません')
                  .split('\n')
                  .map((item, index, array) => (
                    <React.Fragment key={index}>
                      {item}
                      {array.length > index + 1 && <br />}
                    </React.Fragment>
                  ))}
                <EditIcon
                  onClick={() => setNoteEditing(true)}
                  style={{
                    fontSize: 20,
                    cursor: 'pointer',
                    color: '#666',
                    marginLeft: 5,
                  }}
                />
              </div>
            )}
            <hr style={{ marginTop: 32 }} />
          </>
        )}
        <RestockSubscriptionList
          companySlug={company.slug}
          customerId={customer.id}
        />
        <Heading>購入履歴</Heading>
        {customer.shopCustomer ? (
          customerShopLogLoading ? (
            <div className="uk-text-small uk-text-muted">データ取得中...</div>
          ) : (
            <div>
              {orders.map(order => {
                const orderedTime = dayjs(order.ordered * 1000)
                const shopOrderUrl = {
                  base: `${company.shopAccount.shopAdminUrl}/orders/order/${order.uniqueKey}`,
                  shopify: `${company.shopAccount.shopAdminUrl}/orders/${order.uniqueKey}`,
                  makeshop: `${company.shopAccount.shopAdminUrl}/orders/${order.makeshopSystemOrderNumber}`,
                  stores: `${company.shopAccount.shopAdminUrl}/orders/${order.storesNumber}`,
                }[company.shopAccountType]
                return (
                  <OrderRow
                    key={order.uniqueKey}
                    className="uk-link-reset"
                    href={shopOrderUrl}
                    target="_blank"
                    rel="noopener"
                  >
                    <OrderRowImage
                      src={order.firstItemImage || shopItemDefaultImg}
                    />
                    <div className="uk-margin-small-left uk-flex-1 uk-text-small">
                      <div>{orderedTime.format('YYYY/MM/DD HH:mm')}</div>
                      <div className="uk-text-muted">{`￥${order.total.toLocaleString()}（${
                        order.amount
                      }点）`}</div>
                    </div>
                    <div
                      className={`uk-flex-none dispatch-status-label dispatch-status-label--${order.fulfillmentStatusTheme}`}
                    >
                      {order.fulfillmentStatusText}
                    </div>
                  </OrderRow>
                )
              })}
              {orders.length === 0 ? (
                <div className="uk-text-small uk-text-muted">
                  この顧客の購入履歴はありません
                </div>
              ) : (
                customer.shopCustomer &&
                company.shopAccountType === 'base' && (
                  <div className="uk-text-center uk-margin-small-top">
                    <a
                      className="uk-button uk-button-small uk-button-default"
                      href={`${company.shopAccount.shopAdminUrl}/orders/?keyword=${customer.shopCustomer.email}`}
                      target="_blank"
                      rel="noopener"
                    >
                      BASEで確認する
                      <OpenInNewIcon
                        style={{
                          fontSize: 16,
                          verticalAlign: 'text-bottom',
                          marginLeft: 4,
                        }}
                      />
                    </a>
                  </div>
                )
              )}
            </div>
          )
        ) : (
          <div className="uk-text-small uk-text-muted">
            購入情報と紐付いていません
          </div>
        )}
        {customer.customerLineAccount && (
          <>
            <Heading>商品閲覧履歴</Heading>
            {customer.firstOcidConnectedAt ? (
              customerShopLogLoading ? (
                <div className="uk-text-small uk-text-muted">
                  データ取得中...
                </div>
              ) : (
                <div>
                  {accessLogs.length === 0 && (
                    <div className="uk-text-small uk-text-muted">
                      この顧客の商品閲覧履歴はありません
                    </div>
                  )}
                  {_.keys(dateGroupedAccessLogs).map(date => {
                    const logs = dateGroupedAccessLogs[date] || []
                    return (
                      <AccessLogDateBlock key={date}>
                        <div className="uk-text-small uk-text-secondary uk-margin-small-bottom">
                          {date}
                        </div>
                        <div>
                          {logs.map(accessLog => (
                            <AccessLogRow key={accessLog.timestamp}>
                              <AccessLogRowImage
                                src={accessLog.itemImage || shopItemDefaultImg}
                              />
                              <div className="uk-margin-small-left uk-text-small">
                                {accessLog.itemName}
                              </div>
                            </AccessLogRow>
                          ))}
                        </div>
                      </AccessLogDateBlock>
                    )
                  })}
                </div>
              )
            ) : (
              <div className="uk-text-small uk-text-muted">
                閲覧情報と紐付いていません
              </div>
            )}
          </>
        )}
      </DetailContent>
    </>
  )
}

export default CustomerProfile

const Profile = styled.div`
  position: relative;
  text-align: center;
  padding: 40px 0 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`
const SettingsButton = styled.div`
  position: absolute;
  top: 20px;
  right: 60px;
  cursor: pointer;
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    right: 20px;
  }
`
const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media screen and (max-width: ${PC_MIN_WIDTH - 1}px) {
    display: none;
  }
`
const DisplayNameText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-top: 20px;
  h2 {
    font-size: 20px;
    margin: 0;
    margin-right: 5px;
    overflow-wrap: anywhere;
  }
`
const DisplayNameForm = styled.div`
  padding: 0 20px;
`
const DetailContent = styled.div`
  padding: 20px;
`
const Heading = styled.h3`
  font-size: 16px;
  margin: 32px 0 16px;
  &:first-child {
    margin-top: 0;
  }
`

const ProfileRow = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
  p {
    margin: 12px;
    margin-right: 0;
    min-width: 0;
  }
`
const OrderRow = styled.a`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
  > * {
    min-width: 0;
  }
`
const OrderRowImage = styled.img`
  width: 40px;
  height: 40px;
  flex: none;
  object-fit: contain;
`
const AccessLogRow = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
  > * {
    min-width: 0;
  }
  > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
const AccessLogRowImage = styled.img`
  width: 40px;
  height: 40px;
  flex: none;
  object-fit: contain;
`
const AccessLogDateBlock = styled.div`
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  padding: 10px 0 4px;
  &:first-child {
    padding-top: 0;
  }
`
