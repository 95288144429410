import React, { useEffect, useState } from 'react'
import { Customer } from 'types.ts/Customer'

interface Props {
  companySlug: string
}

const ActionRequiredOrUnreadChatPresentCustomerList = ({
  companySlug,
}: Props) => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const res = await fetch(
          `/api/companies/${companySlug}/dashboard/action_required_or_unread_chat_present_customers`
        )
        if (!res.ok) throw new Error('データの取得に失敗しました')
        const data = await res.json()
        setCustomers(data)
      } catch (err: any) {
        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchCustomers()
  }, [companySlug])

  return (
    <div className="uk-section-xsmall">
      <div className="uk-text-left@s">
        <h2 className="uk-h4">チャット未読・要対応リスト</h2>
      </div>
      <div>
        {isLoading && <div className="uk-text-small">読み込み中...</div>}
        {error && <div className="uk-text-small uk-text-danger">{error}</div>}
        {!isLoading && !error && customers.length === 0 && (
          <div className="uk-text-small">対象の顧客はいません。</div>
        )}
        {customers.length > 0 && (
          <div className="uk-section uk-section-default uk-padding-small">
            {customers.map((customer, index) => (
              <div key={customer.chatId}>
                {index > 0 && (
                  <hr className="uk-margin-small-top uk-margin-small-bottom" />
                )}
                <div className="uk-flex uk-flex-between uk-flex-middle">
                  <div className="uk-width-expand uk-flex uk-flex-middle">
                    <img
                      src={customer.profileImage}
                      className="customer-profile-icon uk-margin-small-right"
                    />
                    <div className="uk-width-expand">
                      <div className="text-truncate">
                        <a
                          href={`/companies/${companySlug}/talk_rooms/${customer.chatId}?filter=line_connected`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {customer.displayName}
                        </a>
                      </div>
                      <div className="uk-flex uk-margin-xsmall-top">
                        {customer.chatReadStatus === 'unread_chat_present' && (
                          <div className="chat-status-label chat-status-label--green uk-margin-xsmall-right">
                            未読あり
                          </div>
                        )}
                        {customer.supportStatus === 'action_required' && (
                          <div className="chat-status-label chat-status-label--red">
                            要対応
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="uk-flex-none">
                    <a
                      href={`/companies/${companySlug}/talk_rooms/${customer.chatId}?filter=line_connected`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="uk-button uk-button-default uk-button-small"
                    >
                      チャット
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ActionRequiredOrUnreadChatPresentCustomerList
